import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";

import { interfaceSecureUrl } from "../utils/axiosInterfaceBaseUrl";
import { authSecureUrl } from "../utils/axiosAuthBaseUrl";
import { searchOpenUrl } from "../utils/axiosAuthBaseUrl";
import { refreshToken, getUserInfo } from "./Auth";

const initialState = {
  status: "",
  loading: false,
  changePhoneModalVisible: false,
  newAdvertsLoading: false,
  favoritesLoading: false,
  myAdvertsLoading: false,
  disableBtn: false,
  disableEditUserBtn: false,
  viewsLoading: false,
  submitLoading: false,
  imagesLoading: false,
  advertsLoading: false,
  premiumAdvertsLoading: false,
  topAdvertsLoading: false,
  planLoading: false,
  defaultAdvertType: 1,
  defaultAdvertObject: undefined, // TODO: 1 || undefined
  getAdvertsOffcet: 1,
  regions: null,
  roles: null,
  tarif: null,
  viewsQuantity: 0,
  currentFlat: {
    id: 0,
    value: [],
  },
  minPrice: "",
  maxPrice: "",
  favorites: null,
  myAdverts: null,
  authorItems: null,
  streets: null,
  totalAdverts: 0,
  authorTotalAdverts: 0,
  adverts: null,
  premiumAdverts: null,
  topAdverts: null,
  newAdverts: null,
  oneAdvert: null,
  advertTypes: null,
  advertTypesNotAuth: null,
  advertObjects: null,
  advertObjectsNotAuth: null,
  advertAttributes: null,
  searchAdvertsAttributes: null,
  choosedRegion: { region: "Душанбе", id: 1 },
  mapCoords: [38.575745, 68.796025],
  resetPage: false,
  addAdvertData: null,
  banners: [],
  notFound: false,
};

export const saveAddData = createAsyncThunk(
  "interfaceApi/saveAddData",
  (data) => {
    try {
      localStorage.setItem("saveData", JSON.stringify(data));
      return JSON.stringify(data);
    } catch (error) {
      return;
    }
  }
);

export const getRegions = createAsyncThunk(
  "interfaceApi/getRegions",
  async (_, { rejectWithValue }) => {
    try {
      let uri = "region";
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertType = createAsyncThunk(
  "interfaceApi/getAdvertType",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `dashboard/adtype`;
      const { data } = await interfaceSecureUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoles = createAsyncThunk(
  "interfaceApi/getRoles",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `dashboard/role`;
      const { data } = await interfaceSecureUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTarif = createAsyncThunk(
  "interfaceApi/getTarif",
  async (_, { rejectWithValue }) => {
    try {
      const uri = `tarif/sorted`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertTypeNotAuthorized = createAsyncThunk(
  "interfaceApi/getAdvertTypeNotAuthorized",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `adtype`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertTypeObjects = createAsyncThunk(
  "interfaceApi/getAdvertTypeObjects",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `dashboard/adobject`;
      const { data } = await interfaceSecureUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertTypeObjectsNotAuthorized = createAsyncThunk(
  "interfaceApi/getAdvertTypeObjectsNotAuthorized",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let uri = `adobject`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAdvert = createAsyncThunk(
  "interfaceApi/addAdvert",
  async (
    { body, file, plan, navigate, success },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let uri = `dashboard/ad/create`;
      const { data } = await interfaceSecureUrl.post(uri, body);
      if (plan) {
        dispatch(addAdvertImagePlan({ body: plan, id: data.data.id }));
      }
      dispatch(addAdvertImage({ body: file, id: data.data.id, navigate }));

      success();
      return data;
    } catch (error) {
      message.error("Ошибка при создании объявления");
      return rejectWithValue(error);
    }
  }
);

export const updateAdvert = createAsyncThunk(
  "interfaceApi/updateAdvert",
  async ({ id, body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `dashboard/ad/${id}`;
      const { data } = await interfaceSecureUrl.put(uri, body);
      navigate("/cabinet");
      message.success("Обновлён");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdverts = createAsyncThunk(
  "interfaceApi/getAdverts",
  async (body, { rejectWithValue }) => {
    try {
      const uri = "ad/main";
      const { data } = await searchOpenUrl.post(uri, body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPremiumAdverts = createAsyncThunk(
  "interfaceApi/getPremiumAdverts",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `ad/new/ads?limit=${body.limit}&tarif=premium`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopAdverts = createAsyncThunk(
  "interfaceApi/getTopAdverts",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `ad/new/ads?limit=${body.limit}&tarif=top`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewAdverts = createAsyncThunk(
  "interfaceApi/getNewAdverts",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `ad/new/ads?offset=${body.offset}&limit=${body.limit}&region_id=${body.region_id}&tarif=free`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleAdvert = createAsyncThunk(
  "interfaceApi/getSingleAdvert",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `ad/${id}`;

      const { data } = await searchOpenUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAdvertImage = createAsyncThunk(
  "interfaceApi/addAdvertImage",
  async ({ body, id, navigate, adId }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/ad/${id}/adgallery`;
      const { data } = await interfaceSecureUrl.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (navigate) {
        navigate("/addAdvertFinish");
      }

      dispatch(getSingleAdvert(adId));

      message.success("Добавлен");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAdvertPlan = createAsyncThunk(
  "interfaceApi/addAdvertPlan",
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/ad/${id}/adgallery/plan`;
      const { data } = await interfaceSecureUrl.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(getSingleAdvert(id));

      message.success("Добавлен");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "interfaceApi/deleteImage",
  async ({ adId, id }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/ad/${adId}/adgallery/${id}`;
      const { data } = await interfaceSecureUrl.delete(uri);

      dispatch(getSingleAdvert(adId));

      message.success("Удален");

      return data;
    } catch (error) {
      message.error("Ошибка при удалении");
      return rejectWithValue(error);
    }
  }
);

export const addAdvertImagePlan = createAsyncThunk(
  "interfaceApi/addAdvertImagePlan",
  async ({ body, id }, { rejectWithValue }) => {
    try {
      let uri = `dashboard/ad/${id}/adgallery/plan`;
      const { data } = await interfaceSecureUrl.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertAttributes = createAsyncThunk(
  "interfaceApi/getAdvertAttributes",
  async ({ advId, advObjId }, { rejectWithValue }) => {
    try {
      const uri = `dashboard/attribute?ads_type_id=${advId}&ads_object_id=${advObjId}`;
      const { data } = await interfaceSecureUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertAttributes2 = createAsyncThunk(
  "interfaceApi/getAdvertAttributes2",
  async ({ advId, advObjId }, { rejectWithValue }) => {
    try {
      let uri = `attribute?ads_type_id=${advId || 1}&ads_object_id=${
        advObjId || 1
      }`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertAttributes3 = createAsyncThunk(
  "interfaceApi/getAdvertAttributes3",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `attribute/${id}`;
      const { data } = await searchOpenUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStreets = createAsyncThunk(
  "interfaceApi/getStreets",
  async (_, { rejectWithValue }) => {
    try {
      let uri = "street";
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postFavorites = createAsyncThunk(
  "interfaceApi/postFavorites",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = "dashboard/favorite";
      const { data } = await interfaceSecureUrl.post(uri, body);

      dispatch(getFavorites());

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFavorites = createAsyncThunk(
  "interfaceApi/getFavorites",
  async (_, { rejectWithValue }) => {
    try {
      let uri = "dashboard/favorite";

      const { data } = await interfaceSecureUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetFavorite = createAsyncThunk(
  "interfaceApi/resetFavorite",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/favorite/${id}`;

      const { data } = await interfaceSecureUrl.delete(uri);

      dispatch(getFavorites());

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMyAdverts = createAsyncThunk(
  "interfaceApi/getMyAdverts",
  async (_, { rejectWithValue }) => {
    try {
      let uri = "dashboard/ad/my/ads";

      const { data } = await interfaceSecureUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAdvert = createAsyncThunk(
  "interfaceApi/deleteAdvert",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/ad/${id}`;

      const { data } = await interfaceSecureUrl.delete(uri);

      dispatch(getMyAdverts());

      message.success("Удалён");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdvertsView = createAsyncThunk(
  "interfaceApi/getAdvertsView",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `ad/${id}/viewed`;

      const { data } = await searchOpenUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCodeOfEditUser = createAsyncThunk(
  "interfaceApi/getCodeOfEditUser",
  async (phone, { rejectWithValue }) => {
    try {
      const uri = `login/992${phone}`;
      const { data } = await authSecureUrl.post(uri);
      if (data.success) {
        message.success("Код отправлен");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "interfaceApi/updatePhoneNumber",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `login/992${body.phone}/${body.sms}`;
      const { data } = await authSecureUrl.post(uri);
      return data;
    } catch (error) {
      message.info(error.response.data.data);
      return rejectWithValue(error);
    }
  }
);

export const editUserSelf = createAsyncThunk(
  "interfaceApi/editUserSelf",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/user/update`;

      const { data } = await interfaceSecureUrl.put(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      let token = localStorage.getItem("refresh_token");

      dispatch(getUserInfo());
      dispatch(refreshToken(token));

      return data;
    } catch (error) {
      message.error("Ошибка при обновлении");
      return rejectWithValue(error);
    }
  }
);

export const unpublishAdvert = createAsyncThunk(
  "interfaceApi/unpublishAdvert",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/ad/${id}/un-publish`;
      const { data } = await interfaceSecureUrl.get(uri);

      dispatch(getMyAdverts());
      message.success("Снято с публикации");
      return data;
    } catch (error) {
      if (error.response.data.data === "Объявление на модерации") {
        message.warning("Объявление на модерации");
      }
      return rejectWithValue(error);
    }
  }
);

export const publishAdvert = createAsyncThunk(
  "interfaceApi/publishAdvert",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/ad/${id}/publish`;
      const { data } = await interfaceSecureUrl.get(uri);

      dispatch(getMyAdverts());
      message.success("Опубликован");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAuthorItems = createAsyncThunk(
  "interfaceApi/getAuthorItems",
  async ({ id, offset }, { rejectWithValue }) => {
    try {
      let uri = `ad/user/${id}?offset=${offset}&limit=12`;

      const { data } = await searchOpenUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBanners = createAsyncThunk(
  "interfaceApi/getBanners",
  async (_, { rejectWithValue }) => {
    try {
      const url = `/adv/get_all`;

      const { data } = await searchOpenUrl.get(url);

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const interfaceSlice = createSlice({
  name: "interfaceState",
  initialState,
  reducers: {
    setChoosedRegion: (state, action) => {
      state.choosedRegion = action.payload;
    },
    setMapCoords: (state, action) => {
      state.mapCoords = action.payload;
    },
    setDefaultAdvertType: (state, action) => {
      state.defaultAdvertType = action.payload;
    },
    setDefaultAdvertObject: (state, action) => {
      state.defaultAdvertObject = action.payload;
    },
    setAdvertsOffcet: (state, action) => {
      state.getAdvertsOffcet = action.payload;
    },
    setResetPage: (state, action) => {
      state.resetPage = action.payload;
    },
    findOneAdvert: (state, action) => {
      state.adverts = state.adverts.filter(
        (elem) => elem.id === action.payload
      );
    },
    setCurrentFlat: (state, action) => {
      state.currentFlat = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setChangeModalPhoneVisible: (state, action) => {
      state.changePhoneModalVisible = action.payload;
    },
    setDisableBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAddData.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(saveAddData.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.addAdvertData = action.payload.data;
    });
    builder.addCase(saveAddData, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getRegions.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.regions = action.payload.data;
    });
    builder.addCase(getRegions.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertType.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getAdvertType.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.advertTypes = action.payload.data;
    });
    builder.addCase(getAdvertType.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertTypeNotAuthorized.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getAdvertTypeNotAuthorized.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.advertTypesNotAuth = action.payload.data;
    });
    builder.addCase(getAdvertTypeNotAuthorized.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertTypeObjects.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getAdvertTypeObjects.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.advertObjects = action.payload.data;
    });
    builder.addCase(getAdvertTypeObjects.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertTypeObjectsNotAuthorized.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(
      getAdvertTypeObjectsNotAuthorized.fulfilled,
      (state, action) => {
        state.loading = false;
        state.status = "success";
        state.advertObjectsNotAuth = action.payload.data;
      }
    );
    builder.addCase(getAdvertTypeObjectsNotAuthorized.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertAttributes.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getAdvertAttributes.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.advertAttributes = action.payload.data;
    });
    builder.addCase(getAdvertAttributes.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getAdvertAttributes2.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getAdvertAttributes2.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.searchAdvertsAttributes = action.payload.data;
    });
    builder.addCase(getAdvertAttributes2.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getStreets.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });

    builder.addCase(getStreets.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.streets = action.payload.data;
    });

    builder.addCase(getStreets.rejected, (state) => {
      state.loading = false;
      state.status = "rejected";
    });

    builder.addCase(addAdvert.pending, (state) => {
      state.status = "pending";
      state.submitLoading = true;
    });
    builder.addCase(addAdvert.fulfilled, (state) => {
      state.submitLoading = true;
      state.status = "success";
    });
    builder.addCase(addAdvert.rejected, (state) => {
      state.status = "rejected";
      state.submitLoading = false;
    });

    builder.addCase(updateAdvert.pending, (state) => {
      state.status = "pending";
      state.submitLoading = true;
    });
    builder.addCase(updateAdvert.fulfilled, (state) => {
      state.submitLoading = false;
      state.status = "success";
    });
    builder.addCase(updateAdvert.rejected, (state) => {
      state.status = "rejected";
      state.submitLoading = false;
    });

    builder.addCase(addAdvertImage.pending, (state) => {
      state.status = "pending";
      state.imagesLoading = true;
      state.submitLoading = true;
    });
    builder.addCase(addAdvertImage.fulfilled, (state) => {
      state.imagesLoading = false;
      state.status = "success";
      state.resetPage = true;
      state.submitLoading = false;
    });
    builder.addCase(addAdvertImage.rejected, (state) => {
      state.status = "rejected";
      state.imagesLoading = false;
      state.submitLoading = false;
    });

    builder.addCase(deleteImage.pending, (state) => {
      state.status = "pending";
      state.imagesLoading = true;
    });
    builder.addCase(deleteImage.fulfilled, (state) => {
      state.imagesLoading = false;
      state.status = "success";
    });
    builder.addCase(deleteImage.rejected, (state) => {
      state.status = "rejected";
      state.imagesLoading = false;
    });

    builder.addCase(addAdvertImagePlan.pending, (state) => {
      state.status = "pending";
      state.planLoading = true;
    });
    builder.addCase(addAdvertImagePlan.fulfilled, (state) => {
      state.planLoading = false;
      state.status = "success";
    });
    builder.addCase(addAdvertImagePlan.rejected, (state) => {
      state.status = "rejected";
      state.planLoading = false;
    });

    builder.addCase(getAdverts.pending, (state) => {
      state.status = "pending";
      state.advertsLoading = true;
    });
    builder.addCase(getAdverts.fulfilled, (state, action) => {
      state.advertsLoading = false;
      state.adverts = action.payload.data;
      state.totalAdverts = action.payload.meta.total;
    });
    builder.addCase(getAdverts.rejected, (state) => {
      state.status = "rejected";
      state.advertsLoading = false;
    });

    builder.addCase(getPremiumAdverts.pending, (state) => {
      state.status = "pending";
      state.premiumAdvertsLoading = true;
    });
    builder.addCase(getPremiumAdverts.fulfilled, (state, action) => {
      state.premiumAdvertsLoading = false;
      state.premiumAdverts = action.payload.data;
    });
    builder.addCase(getPremiumAdverts.rejected, (state) => {
      state.status = "rejected";
      state.premiumAdvertsLoading = false;
    });

    builder.addCase(getTopAdverts.pending, (state) => {
      state.status = "pending";
      state.topAdvertsLoading = true;
    });
    builder.addCase(getTopAdverts.fulfilled, (state, action) => {
      state.topAdvertsLoading = false;
      state.topAdverts = action.payload.data;
    });
    builder.addCase(getTopAdverts.rejected, (state) => {
      state.status = "rejected";
      state.topAdvertsLoading = false;
    });

    builder.addCase(getNewAdverts.pending, (state) => {
      state.status = "pending";
      state.newAdvertsLoading = true;
    });
    builder.addCase(getNewAdverts.fulfilled, (state, action) => {
      state.newAdvertsLoading = false;
      state.newAdverts = action.payload.data;
    });
    builder.addCase(getNewAdverts.rejected, (state) => {
      state.status = "rejected";
      state.newAdvertsLoading = false;
    });

    builder.addCase(getSingleAdvert.pending, (state) => {
      state.status = "pending";
      state.advertsLoading = true;
      state.notFound = false;
    });
    builder.addCase(getSingleAdvert.fulfilled, (state, action) => {
      state.oneAdvert = action.payload.data;
      state.advertsLoading = false;
      state.notFound = false;
    });
    builder.addCase(getSingleAdvert.rejected, (state, action) => {
      state.status = "rejected";
      state.advertsLoading = false;
      if (action.payload.response.data.data) {
        state.notFound = true;
      }
    });

    builder.addCase(postFavorites.pending, (state) => {
      state.status = "pending";
      state.favoritesLoading = true;
    });
    builder.addCase(postFavorites.fulfilled, (state) => {
      state.favoritesLoading = false;
    });
    builder.addCase(postFavorites.rejected, (state) => {
      state.status = "rejected";
      state.favoritesLoading = false;
    });

    builder.addCase(getFavorites.pending, (state) => {
      state.status = "pending";
      state.favoritesLoading = true;
    });
    builder.addCase(getFavorites.fulfilled, (state, action) => {
      state.favorites = action.payload.data;
      state.favoritesLoading = false;
    });
    builder.addCase(getFavorites.rejected, (state) => {
      state.status = "rejected";
      state.favoritesLoading = false;
    });

    builder.addCase(resetFavorite.pending, (state) => {
      state.status = "pending";
      state.favoritesLoading = true;
    });
    builder.addCase(resetFavorite.fulfilled, (state) => {
      state.favoritesLoading = false;
    });
    builder.addCase(resetFavorite.rejected, (state) => {
      state.status = "rejected";
      state.favoritesLoading = false;
    });

    builder.addCase(getMyAdverts.pending, (state) => {
      state.status = "pending";
      state.myAdvertsLoading = true;
    });
    builder.addCase(getMyAdverts.fulfilled, (state, action) => {
      state.myAdvertsLoading = false;
      state.myAdverts = action.payload.data;
    });
    builder.addCase(getMyAdverts.rejected, (state) => {
      state.status = "rejected";
      state.myAdvertsLoading = false;
    });

    builder.addCase(getAdvertsView.pending, (state) => {
      state.status = "pending";
      state.viewsLoading = true;
    });
    builder.addCase(getAdvertsView.fulfilled, (state, action) => {
      state.viewsLoading = false;
      state.viewsQuantity = action.payload.data.qnt_view;
    });
    builder.addCase(getAdvertsView.rejected, (state) => {
      state.status = "rejected";
      state.viewsLoading = false;
    });

    builder.addCase(updatePhoneNumber.pending, (state) => {
      state.status = "pending";
      state.loading = true;
      state.disableBtn = true;
    });
    builder.addCase(updatePhoneNumber.fulfilled, (state) => {
      state.loading = false;
      state.changePhoneModalVisible = false;
    });
    builder.addCase(updatePhoneNumber.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getRoles.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload.data;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getTarif.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getTarif.fulfilled, (state, action) => {
      state.loading = false;
      state.tarif = action.payload.data;
    });
    builder.addCase(getTarif.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(editUserSelf.pending, (state) => {
      state.status = "pending";
      state.loading = true;
      state.disableEditUserBtn = true;
    });
    builder.addCase(editUserSelf.fulfilled, (state) => {
      state.loading = false;
      state.disableEditUserBtn = false;
      notification["success"]({
        description: "Успешно обновлено!",
      });
    });
    builder.addCase(editUserSelf.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
      state.disableEditUserBtn = false;
    });

    builder.addCase(deleteAdvert.pending, (state) => {
      state.status = "pending";
      state.myAdvertsLoading = true;
    });
    builder.addCase(deleteAdvert.fulfilled, (state) => {
      state.myAdvertsLoading = false;
    });
    builder.addCase(deleteAdvert.rejected, (state) => {
      state.status = "rejected";
      state.myAdvertsLoading = false;
    });

    builder.addCase(unpublishAdvert.pending, (state) => {
      state.status = "pending";
      state.myAdvertsLoading = true;
    });
    builder.addCase(unpublishAdvert.fulfilled, (state) => {
      state.myAdvertsLoading = false;
    });
    builder.addCase(unpublishAdvert.rejected, (state) => {
      state.status = "rejected";
      state.myAdvertsLoading = false;
    });

    builder.addCase(getAuthorItems.pending, (state) => {
      state.status = "pending";
      state.advertsLoading = true;
    });
    builder.addCase(getAuthorItems.fulfilled, (state, action) => {
      state.advertsLoading = false;
      state.authorItems = action.payload.data;
      state.authorTotalAdverts = action.payload.meta.total;
    });
    builder.addCase(getAuthorItems.rejected, (state) => {
      state.status = "rejected";
      state.advertsLoading = false;
    });

    builder.addCase(getBanners.fulfilled, (state, action) => {
      state.banners = action.payload;
    });
  },
});

export const {
  setChoosedRegion,
  setMapCoords,
  setDefaultAdvertType,
  setDefaultAdvertObject,
  setAdvertsOffcet,
  setResetPage,
  findOneAdvert,
  setChangeModalPhoneVisible,
  setCurrentFlat,
  setMaxPrice,
  setMinPrice,
  setDisableBtn,
} = interfaceSlice.actions;

export default interfaceSlice.reducer;
